import React from 'react'
import { useLocalization } from 'gatsby-theme-i18n'
// import { tr } from 'js/translations.js'

class NotFoundPage extends React.Component {
  componentDidMount() {
    // pageLoad()
  }

  render() {
    return <Inner />
  }
}

const Inner = () => {
  const { locale } = useLocalization()
  return (
    <div id='page-not-found' className='page'>
      <h1>404: not found</h1>
    </div>
  )
}

export default NotFoundPage
